<template>
  <v-avatar
      v-if="img && !!img.URL"
      :size="customWidth"
      tile>
    <v-img :src="img.URL" contain/>
  </v-avatar>
  <Avatar v-else :username="name" :size="customWidth"/>
</template>

<script>
import Avatar from "vue-avatar";

export default {
  name: "DisplayPicture",

  props: {
    img: {type: Object, default: () => {}},
    customWidth: {type: Number, default: 100},
    name: {type: String, default: null},
  },

  components: {
    Avatar,
  }
}
</script>

<style scoped>

</style>